import {
  FiltroEscola,
  FiltroGRE,
  HeaderAnoGlobal,
  LoaderComponent,
} from "app/shared/components";
import {
  CardsSection,
  ListaPlanosSection,
  PlanosGraficoSection,
} from "./components";
import { useRole, useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { buscaTipoPlanos } from "app/services/plano";
import { useEffect } from "react";
import { setTiposPlano } from "app/redux/reducers/dashboardSlice";
import { useDispatch } from "react-redux";
import { Box, Grid, Paper } from "@mui/material";

const DashboardPage = () => {
  const { isRede, isSup } = useRole();
  const dispatch = useDispatch();

  const handleLoadTipos = (data) => {
    const tiposPlanos = data || [];
    dispatch(setTiposPlano(tiposPlanos));
  };

  const {
    request: requestTiposPlano,
    response: responseTiposPlano,
    retry: retryTiposPlano,
  } = useService(buscaTipoPlanos, false, handleLoadTipos, () => { }, { cache: true, cacheKey: "getTiposPlano" });

  useEffect(() => {
    let params = { limit: 0 };
    requestTiposPlano(params);
  }, []);

  return (
    <>
      <LoaderComponent
        {...responseTiposPlano}
        retry={retryTiposPlano}
        message="Buscando tipos"
        errorMessage="Falha ao carregar tipos de plano"
      />
      <HeaderAnoGlobal
        titulo={"Acompanhamento e aplicação de recursos escolares (PAAR)"}
        colorTitulo={Theme.colors.blue.dark}
      />
      {(isRede || isSup) && (
        <Box p={2} my="10px" component={Paper}>
          <Grid container spacing={1}>
            {!isSup && <Grid item md={6} sm={12} xs={12}>
              <FiltroGRE />
            </Grid>}
            <Grid item md={6} sm={12} xs={12}>
              <FiltroEscola />
            </Grid>
          </Grid>
        </Box>
      )}
      <CardsSection />
      <PlanosGraficoSection />
      <ListaPlanosSection />
    </>
  );
};

export default DashboardPage;

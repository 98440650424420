import { Autocomplete, Grid, Stack, TextField, Typography } from "@mui/material";
import { buscaGroupedCnpjs } from "app/services/aplicacao";
import { LoaderComponent, NumberFormat, QuantNumberFormat } from "app/shared/components";
import { formatTypedCNPJ } from "app/shared/helpers";
import { useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { validarCNPJ } from "../../RecursoCadastro/utils";

const CusteioSection = ({ value, onChange, setError }) => {
  const isValid = (() => {
    const hasCusto = !!value.custo_capital || !!value.custo_custeio;
    const hasInfos = value.quantidade > 0;
    return hasCusto && hasInfos;
  })();

  const {
    request: getGroupedCnpjsReq,
    response: responseCNPJs,
    retry: retryGroupedCnpjs,
  } = useService(buscaGroupedCnpjs);
  const cnpjs = responseCNPJs?.data?.map((fornecedor) => formatTypedCNPJ(fornecedor.cnpj_fornecedor)) || [];
  const cnpjOptions = cnpjs.filter((cpnj) => cpnj);
  const cnpjFormatado = value.cnpj_fornecedor?.replace(/\D/g, "");
  const isCNPJValid = validarCNPJ(cnpjFormatado);
  const existeCNPJNaLista = isCNPJValid || !cnpjFormatado;
  useEffect(() => {
    getGroupedCnpjsReq({ limit: 0 });
  }, []);

  useEffect(() => {
    setError(!existeCNPJNaLista ? "Necessário informar um CNPJ válido" : undefined);
  }, [value.cnpj_fornecedor, cnpjOptions]);

  const borderColor = isValid ? `1px solid ${Theme.colors.gray.ligth}` : `2px solid ${Theme.colors.red}`;

  const changeCNPJ = (e, value) => onChange({ cnpj_fornecedor: value });

  const TextBlue = ({ children, ...rest }) => (
    <Typography
      variant="body2"
      fontWeight="bold"
      color={Theme.colors.blue.dark}
      {...rest}
    >
      {children}
    </Typography>
  );

  const TextBlue12 = ({ children, ...rest }) => (
    <TextBlue fontSize={12} {...rest}>
      {children}
    </TextBlue>
  );

  return (
    <Stack border={borderColor} borderRadius={1} p={1} mb="10px">
      <Stack direction="row" mb="10px">
        <FiAlertCircle color={Theme.colors.blue.dark} />
        <TextBlue ml="5px">Ao menos um custo deve ser informado</TextBlue>
      </Stack>
      <Grid container spacing={1}>
        <Grid item md={3} sm={6} xs={12}>
          <TextBlue12>CUSTO CAPITAL</TextBlue12>
          <TextField
            size="small"
            value={value.custo_capital}
            onChange={(e) => onChange({ custo_capital: e.target.value })}
            fullWidth
            placeholder="R$ 0,00"
            InputProps={{
              inputComponent: NumberFormat,
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextBlue12>CUSTO CUSTEIO</TextBlue12>
          <TextField
            size="small"
            value={value.custo_custeio}
            onChange={(e) => onChange({ custo_custeio: e.target.value })}
            fullWidth
            placeholder="R$ 0,00"
            InputProps={{
              inputComponent: NumberFormat,
            }}
          />
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <TextBlue12>QUANTIDADE</TextBlue12>
          <TextField
            size="small"
            fullWidth
            value={value.quantidade}
            onChange={(e) => onChange({ quantidade: e.target.value })}
            InputProps={{
              inputComponent: QuantNumberFormat,
            }}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextBlue12>CNPJ DO FORNECEDOR</TextBlue12>
          <Autocomplete
            disablePortal
            options={cnpjOptions}
            fullWidth
            size="small"
            value={formatTypedCNPJ(value.cnpj_fornecedor)}
            inputValue={formatTypedCNPJ(value.cnpj_fornecedor)}
            onInputChange={changeCNPJ}
            onChange={changeCNPJ}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, maxLength: 18 }}
              />
            )}
          />
        </Grid>
      </Grid>
      <LoaderComponent
        {...responseCNPJs}
        retry={retryGroupedCnpjs}
        message="Buscando lista de CNPJs"
        errorMessage="Falha ao carregar CNPJs"
      />
    </Stack>
  );
};

export default CusteioSection;

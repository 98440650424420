import { Button } from "@mui/material";
import { useState } from "react";
import ModalDetalhesItem from "./ModalDetalhesItem";
import ModalAnalisarItem from "./ModalAnalisarItem";
import ModalHistorico from "./ModalHistorico";
import ModalAnexosItem from "./ModalAnexosItem";
import { useRole } from "app/shared/hooks";
import { STATUS_DEFERIDO_ITEM, STATUS_INICIADO_PLANO } from "app/shared/constants";
import { useSelector } from "react-redux";

const ActionItem = ({ item, allItens }) => {
  const {recursoSelecionado} = useSelector(state=>state.recurso);
  const [historicoOpen, setHistoricoOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [analisarOpen, setAnalisarOpen] = useState(false);
  const [anexosOpen, setAnexosOpen] = useState(false);
  const { isRede } = useRole();
  
  const execucao = item.execucao === "1";
  const itemDeferido = item?.situacao?.status === STATUS_DEFERIDO_ITEM;
  const status_plano = execucao ? recursoSelecionado?.status_execucao : recursoSelecionado?.status;
  const planoStatusIniciado = status_plano === STATUS_INICIADO_PLANO;
  const permiteAnalise = isRede && !itemDeferido && !planoStatusIniciado;

  const BtnDefault = ({ children, style, ...rest }) => (
    <Button
      disableElevation
      style={{ marginBottom: 4, fontSize: "11px", padding: 0, ...style }}
      fullWidth
      size="small"
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );

  return (
    <>
      <BtnDefault onClick={() => setDetalhesOpen(true)}>DETALHES</BtnDefault>
      {permiteAnalise && (
        <BtnDefault color="info" onClick={() => setAnalisarOpen(true)}>
          ANALISAR
        </BtnDefault>
      )}
      {itemDeferido && (
        <BtnDefault style={{ backgroundColor: "#402E7A" }} onClick={() => setAnexosOpen(true)}>
          ANEXOS ({item.anexos?.length||0})
        </BtnDefault>
      )}
      <BtnDefault color="secondary" onClick={() => setHistoricoOpen(true)}>
        HISTÓRICO
      </BtnDefault>
      {detalhesOpen && (
        <ModalDetalhesItem
          item={item}
          isOpen={detalhesOpen}
          onClose={() => setDetalhesOpen(false)}
        />
      )}
      {analisarOpen && (
        <ModalAnalisarItem
          item={item}
          allItens={allItens}
          isOpen={analisarOpen}
          onClose={() => setAnalisarOpen(false)}
        />
      )}
      {historicoOpen && (
        <ModalHistorico
          item={item}
          open={historicoOpen}
          onClose={() => setHistoricoOpen(false)}
          paramsId={{
            item_id: item.id,
          }}
        />
      )}
      {anexosOpen && (
        <ModalAnexosItem
          item={item}
          isOpen={anexosOpen}
          onClose={() => setAnexosOpen(false)}
        />
      )}
    </>
  );
};

export default ActionItem;

import {
  Accordion,
  AccordionDetails,
  Grid,
  Typography,
} from "@mui/material";
import { Theme } from "app/shared/utils";
import { v4 as uuidv4 } from "uuid";
import HeaderAnexo from "./HeaderAnexo";

const AnexosForm = ({ defaultValue = [], onChange=()=>{}, tipos=[], loading}) => {
  
  const handleUpload = (tipo, files) => {
    const uploadFiles = files.map((file) => ({
      file,
      id: `${uuidv4()}-new`,
      name: file.name,
      preview: URL.createObjectURL(file),
      type: file.type,
      tipo: tipo,
    }));
    onChange([...defaultValue, ...uploadFiles]);
  };

  const handleRemoveFile = (id) => {
    onChange(defaultValue.filter((file) => file.id !== id));
  };

  return (
    <Grid container direction="column" mt="20px">
      <Typography
        variant="body1"
        fontWeight="bold"
        color={Theme.colors.blue.dark}
      >
        ANEXOS
      </Typography>
      {tipos.map(({label, tipo, defaultExpanded, CustomBody},index) => (
        <Accordion defaultExpanded={defaultExpanded} key={`acc-${index}`}>
          <HeaderAnexo {...{defaultValue, tipo, label}}/>
          <AccordionDetails>
            <CustomBody {...{defaultValue, handleUpload, handleRemoveFile, tipo, label, disabled: loading}}/>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default AnexosForm;

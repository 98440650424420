import { Button, Grid, TextField, Typography } from "@mui/material";
import { GenericModal } from "app/shared/components";
import { Theme } from "app/shared/utils";
import CusteioSection from "./CusteioSection";
import { useReducer, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import toastr from "toastr";
import { STATUS_CRIADO_ITEM } from "app/shared/constants";

const ModalItemForm = ({ open, onClose, onSave, item, macroAcao }) => {
  const isEdit = !!item;
  const initialValue = item || {
    acao: "",
    inicio: null,
    termino: null,
    descricao: "",
    responsavel: "",
    financiador: "",
    custo_capital: "",
    custo_custeio: "",
    quantidade: 1,
    cnpj_fornecedor: "",
    situacao:{status:STATUS_CRIADO_ITEM},
  };

  const [errorCusteio, setErrorCusteio] = useState();
  const [newItem, updateNewItem] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    initialValue
  );


  const isValidForm = () => {
    const errors = [];
    if (!newItem.acao.trim()) errors.push("Necessário informar ação");
    if (newItem.quantidade <= 0) errors.push("Necessário informar quantidade");
    const hasCusto = !!newItem.custo_capital || !!newItem.custo_custeio;
    if (!hasCusto) errors.push("Necessário informar ao menos um custo");
    if (!hasCusto) errors.push("Necessário informar ao menos um custo");
    if (errorCusteio) errors.push(errorCusteio);

    errors.forEach((erro) => toastr.warning(erro));

    return errors.length === 0;
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!isValidForm()) return;
    const id = item?.id || `${uuidv4()}-new`;
    onSave?.({ id, ...newItem });
  };

  return (
    <GenericModal
      title={isEdit ? "EDITAR ITEM" : "NOVO ITEM"}
      open={open}
      onClose={onClose}
      size="sm"
      preventCloseClickOutside
    >
      <p style={{fontSize: 14, color: '#666', fontWeight: 'bold', marginTop: 0}} >MACRO AÇÃO: {macroAcao}</p>
      <Grid container direction="column" my="10px">
        <Typography
          variant="body2"
          fontWeight="bold"
          color={Theme.colors.blue.dark}
        >
          DESCRIÇÃO*
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={newItem.acao}
          onChange={(e) => updateNewItem({ acao: e.target.value })}
        />
      </Grid>
      <CusteioSection
        value={newItem}
        onChange={(newCusteio) => updateNewItem(newCusteio)}
        setError={setErrorCusteio}
      />
      <Grid container justifyContent="end" mt="10px">
        <Button
          variant="outlined"
          color="secondary"
          style={{
            marginRight: 10,
          }}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          style={{
            justifySelf: "flex-end",
          }}
          variant="contained"
          onClick={handleSave}
        >
          {"SALVAR"}
        </Button>
      </Grid>
    </GenericModal>
  );
};

export default ModalItemForm;

import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import {  LogoImage } from "./styles";
import { FiUser, FiLock } from "react-icons/fi";
import { useState } from "react";
import toastr from 'toastr'
import { useService } from "app/shared/hooks";
import { doLogin } from "app/services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_LOGADO } from "app/shared/constants/StorageKeys";
import { useDispatch } from "react-redux";
import { setUser } from "app/redux/reducers/globalSlice";
import { loginMobi } from "app/services/auth/service";
import { DASHBOARD } from "app/shared/constants/HashRouter";

const LoginForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const preUrl = query.get("prevUrl");
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: "",
    senha: "",
  });

  const onSuccess = (result) => {
    localStorage.setItem(USER_LOGADO, window.btoa(JSON.stringify(result)));
    loginMobi(result);
    dispatch(setUser(result));
    navigate(preUrl || DASHBOARD);
  };

  const onError = () => {
    toastr.warning("Não foi possível entrar no aplicativo. Tente novamente.");
  }

  const {
    request: doLoadinReq,
    response: { loading },
  } = useService(doLogin, true, onSuccess, onError);

  const handleChangeCredentials = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const isFormPreenchido = !!credentials.email && !!credentials.senha;

  const handleSubmit = (event) => {
    event.preventDefault();
    doLoadinReq(credentials);
  };

  return (
    <>
      <LogoImage src="img/logo-plus-title.svg" />
      <Paper
        style={{
          margin: "0px 10px",
          width: "100%",
          maxWidth: "320px",
        }}
        elevation={4}
      >
        <form onSubmit={handleSubmit}>
          <Grid container width="100%" maxWidth="320px" padding="20px">
            <TextField
              name="email"
              label="Email"
              disabled={loading}
              style={{
                width: "100%",
              }}
              value={credentials.email}
              onChange={handleChangeCredentials}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FiUser />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              label="Senha"
              type="password"
              name="senha"
              disabled={loading}
              style={{
                width: "100%",
              }}
              value={credentials.senha}
              onChange={handleChangeCredentials}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FiLock />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </Grid>
          <Button
            fullWidth
            variant="contained"
            disabled={!isFormPreenchido || loading}
            type="submit"
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {loading ? "CARREGANDO..." : "ENTRAR"}
          </Button>
        </form>
        {loading && <LinearProgress variant="query" />}
      </Paper>
    </>
  );
};

export default LoginForm;

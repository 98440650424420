import { Grid, Paper, Stack } from "@mui/material";
import { AnexosSection, MacroAcao, ActionHeader } from "./components";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useService, useUnmount } from "app/shared/hooks";
import { HeaderAnoGlobal, LoaderComponent } from "app/shared/components";
import { buscaRecursoPorId } from "app/services/recurso";
import { useDispatch, useSelector } from "react-redux";
import { setRecursoSelecionado } from "app/redux/reducers/recursoSlice";
import { InfoBasePlano, InfoDataPlano } from "../components";

const RecursoDetalhesPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { recursoSelecionado } = useSelector((state) => state.recurso);

  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const handleLoadRecursos = (data) => {
    if (data) {
      dispatch(setRecursoSelecionado(data));
    }
  };

  useUnmount(() => {
    dispatch(setRecursoSelecionado(null));
  });

  const { request, response, retry } = useService(buscaRecursoPorId, false, handleLoadRecursos);
  useEffect(() => {
    if (id) {
      let params = {
        id: id,
        with: "parcela,escola.superintendencia,anexos,macro_acao_escolas.itens.situacao,macro_acao_escolas.macro_acao,macro_acao_escolas.itens.anexos",
      }
      request(params);
    }
  }, [id]);

  return (
    <>
      <HeaderAnoGlobal
        hasBackButton={true}
        showAnoSelect={false}
        titulo="Detalhes do plano"
        onBack={()=>navigate("/recurso")}  
      />
      <LoaderComponent
        {...response}
        retry={retry}
        message="Buscando recurso"
        errorMessage="Falha ao carregar recurso"
      >
        {recursoSelecionado && (
          <>
            <Grid
              container
              mt="16px"
              padding="16px 16px 5px"
              position="relative"
              component={Paper}
            >
              <Grid item xs={12} md={7}>
                <InfoBasePlano recurso={recursoSelecionado} />
              </Grid>
              <Grid item md={5}>
                <ActionHeader recurso={recursoSelecionado} />
              </Grid>
            </Grid>
            <Stack mt="10px" padding="16px 16px 5px" component={Paper}>
              <InfoDataPlano recurso={recursoSelecionado} />
              <MacroAcao />
              <AnexosSection anexos={recursoSelecionado.anexos} />
            </Stack>
          </>
        )}
      </LoaderComponent>
    </>
  );
};
export default RecursoDetalhesPage;

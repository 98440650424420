import { TipoAnexo } from "app/shared/constants";
import { cnpjToNumber, convertToFormData, getImageBlob } from "app/shared/helpers";
import toastr from "toastr";

export const isValidForm = (form) => {
  const errors = [];
  if (!form.meta || !form.meta.trim()) errors.push("Necessário informar meta");
  if (!form.objetivo_estrategico || !form.objetivo_estrategico.trim())
    errors.push("Necessário informar Objetivo estratégico");
  if (!form.justificativa || !form.justificativa.trim())
    errors.push("Necessário informar Justificativa");
  errors.forEach((erro) => toastr.warning(erro));
  return errors.length === 0;
};

export const formataAnexosRequest = (anexos) => {
  let anexosFormated = {};

  anexos.forEach((anexo, index) => {
    if (!String(anexo.id).includes("new")) {
      anexosFormated[`info_anexos[${index}][id]`] = anexo.id;
    }
    anexosFormated[`info_anexos[${index}][tipo]`] = anexo.tipo;
    anexosFormated[`anexos[${index}]`] = anexo.file;
  });

  return anexosFormated;
};

export const formataRequestRecurso = (form) => {
  const hasId = !!form?.id;
  const formatedData = {
    ...(hasId ? { id: form?.id, _method: "put" } : {}),
    parcela_id: form.parcelaId,
    objetivo_estrategico: form.objetivo_estrategico,
    meta: form.meta,
    justificativa: form.justificativa,
    escola_id: form.escola_id,
    ...formatMacroAcoesRequest(form.macroAcoes),
    ...formataAnexosRequest(form.anexos),
  };

  return convertToFormData(formatedData);
};

export const formatMacroAcoesRequest = (macroAcoes) => {
  let macroAcoesFormated = {};

  macroAcoes.forEach((acao, index) => {
      macroAcoesFormated[`macro_acoes[${index}][id]`]= acao.id 
      macroAcoesFormated[`macro_acoes[${index}][responsavel]`]= acao.responsavel || ""
      macroAcoesFormated[`macro_acoes[${index}][financiador]`]= acao.financiador || ""
      macroAcoesFormated[`macro_acoes[${index}][inicio]`]= acao.inicio || ""
      macroAcoesFormated[`macro_acoes[${index}][termino]`]= acao.termino || ""
      macroAcoesFormated[`macro_acoes[${index}][descricao]`]= acao.descricao || ""

      acao.itens.forEach((item, indexItem) => {
        if (!String(item.id).includes("new")) {
           macroAcoesFormated[`macro_acoes[${index}][itens][${indexItem}][id]`] = item.id
        }
        macroAcoesFormated[`macro_acoes[${index}][itens][${indexItem}][acao]`] = item.acao
        macroAcoesFormated[`macro_acoes[${index}][itens][${indexItem}][cnpj_fornecedor]`] = cnpjToNumber(item.cnpj_fornecedor);
        macroAcoesFormated[`macro_acoes[${index}][itens][${indexItem}][custo_capital]`] = item.custo_capital
        macroAcoesFormated[`macro_acoes[${index}][itens][${indexItem}][custo_custeio]`] = item.custo_custeio
        macroAcoesFormated[`macro_acoes[${index}][itens][${indexItem}][quantidade]`] = item.quantidade
      })
  });

  return macroAcoesFormated;
}

export const formatResponseAnexos = (anexos) => {
  const pormises = anexos
    .filter((anexo) => anexo.tipo + "" !== TipoAnexo.COMPROVANTE_CREDITO + "")
    .map(async (anexo) => {
      return {
        file: await getImageBlob(anexo.url, anexo.nome),
        id: anexo.id,
        name: anexo.nome,
        preview: anexo.url,
        type: anexo.url.slice(-3),
        tipo: anexo.tipo,
      };
    });
  return Promise.all(pormises);
};



const formatItens = (itens) => {
  return itens ? itens.map((item) => {
    return {
      ...item,
      descricao: item.descricao || "",
    };
  }): [];
};

export const formatResponseMacroAcoes = async (data) => {
  if (!data) return [];
  return data.map(d => ({
    ...d,
    macro_acao_descricao: d?.macro_acao?.nome,
  }));
};

export const formateResponseRecurso = async (data) => {
  if (!data) return null;
  const formatedAnexos = await formatResponseAnexos(data.anexos);
  const formatedItens = formatItens(data.itens);
  const formatedAcoes = await formatResponseMacroAcoes(data.macro_acao_escolas);
  return { ...data, anexos: formatedAnexos, itens: formatedItens,  macroAcoes: formatedAcoes };
};

export const validarCNPJ = (cnpj) => {
  if (!cnpj) return false;
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado+'' !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado+'' !== digitos.charAt(1)) return false;

  return true;
};

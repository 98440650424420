import { useService } from "app/shared/hooks";
import React, { useEffect } from "react";
import LoaderComponent from "../LoaderComponent";
import { setItemSituacoes } from "app/redux/reducers/globalSlice";
import { useDispatch } from "react-redux";
import { buscaItemTipoSituacoes } from "app/services/item-tipo-situacoes";

const RequestItemStatus = () => {
  const dispatch = useDispatch();
  const onSuccessItemStatus = (data) => {
    let obj = data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    dispatch(setItemSituacoes(obj));
  };
  const { request, response, retry } = useService(
    buscaItemTipoSituacoes,
    false,
    onSuccessItemStatus,
    () => {},
    { cache: true, cacheKey: "ITEM_STATUS_KEY" }
  );
  useEffect(() => {
    request({ limit: 0 });
  }, []);

  return (
    <>
      <LoaderComponent
        {...response}
        retry={retry}
        message="Carregando situações dos itens"
        errorMessage="Não foi possível carregar situações dos itens"
      />
    </>
  );
};

export default RequestItemStatus;

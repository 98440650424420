import { Button, Paper, Stack } from "@mui/material";
import { buscaRecursoPorId } from "app/services/recurso";
import { HeaderAnoGlobal, LoaderComponent, LoaderModalComponent } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoBasePlano, InfoDataPlano } from "../components";
import { MacroAcaoListForm } from "./components";
import { saveAllMacroAcao } from "app/services/macro-acoes-escola";
import { cnpjToNumber } from "app/shared/helpers";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { clearCache } from "app/redux/reducers/cacheSlice";
import { STATUS_CRIADO_ITEM } from "app/shared/constants";

const RecursoExecucaoPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [macroAcoes, setMacroAcoes] = useState([]);

  const onSuccess = (data) => {
    if (data) {
      let macroAcoes  = data.macro_acao_escolas.map(macroacao => {
        let temExecucao = macroacao.itens.find(item => item.execucao+'' === '1');
        let execucao = temExecucao?'1':'0';
        let itens = macroacao.itens.filter(item => item.execucao+"" === execucao);
        if(!temExecucao){// tratamento para o plano ficar como iniciado ao entrar a primeira vez na execução
          itens = itens.map(item=>({...item, situacao:{status:STATUS_CRIADO_ITEM}}));
        }
        return {...macroacao, itens};
      })
      setMacroAcoes(macroAcoes);
    }
  };

  const { request, response, retry } = useService(
    buscaRecursoPorId,
    false,
    onSuccess
  );

  const onSuccessSave = () => {
     //LIMPA CACHE DAS LISTAGENS
     dispatch(clearCache("getParcelasSemPlanoAll"))
     dispatch(clearCache("getParcelasPlanoAtualAll"))

    toastr.success("Plano executado com sucesso!")
    navigate(`/recurso/detalhes?id=${id}`);
  }
  
  const {
    request: saveMacroacao,
    response: responseSave,
    retry:retrySave,
  } = useService(saveAllMacroAcao, false, onSuccessSave);

  const getRecurso = () => {
    let params = {
      id: id,
      with: "parcela,macro_acao_escolas.macro_acao,macro_acao_escolas.itens.situacao",
    };
    request(params);
  };

  useEffect(() => {
    getRecurso();
  }, []);

  const recurso = response.data || null;

  const formataItensMacroAcaoParams = () => {
    let params = {}
    macroAcoes.forEach((macroAcao, mAcaoIdx) => {
      params[`macro_acoes[${mAcaoIdx}][id]`] = macroAcao.id;
      macroAcao.itens.forEach((item, itemIdx) => {
        params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][acao]`] = item.acao;
        params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][custo_custeio]`] = item.custo_custeio;
        params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][custo_capital]`] = item.custo_capital;
        params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][quantidade]`] = item.quantidade;
        params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][cnpj_fornecedor]`] = cnpjToNumber(item.cnpj_fornecedor);
        params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][execucao]`] = 1;
        if(item.execucao+'' === '1'){
          params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][id]`] = item.id;
          if(item.item_planejamento_id){
            params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][item_planejamento_id]`] = item.item_planejamento_id;
          }
        }else if (!item.itemNovo) {
          params[`macro_acoes[${mAcaoIdx}][itens][${itemIdx}][item_planejamento_id]`] = item.id;
        }
      });
    });
    return params
  }

  const saveExecucao = () => {
    let params = { plano_id: id, ...formataItensMacroAcaoParams() };
    saveMacroacao(params);
  };

  return (
    <>
      {(responseSave.loading || responseSave.error) && (
        <LoaderModalComponent
          {...responseSave}
          retry={retrySave}
        />
      )}
      <HeaderAnoGlobal
        showAnoSelect={false}
        hasBackButton
        titulo="Execução do plano"
      />
      <LoaderComponent
        {...response}
        retry={retry}
        message="Buscando dados do recurso, aguarde um momento"
      >
        {recurso && (
          <>
            <Stack padding="16px 16px 5px" component={Paper}>
              <InfoBasePlano recurso={recurso} />
            </Stack>
            <Stack mt={1} padding="16px 16px 5px" component={Paper}>
              <InfoDataPlano recurso={recurso} />
              <MacroAcaoListForm {...{ macroAcoes, setMacroAcoes }} />
              <Stack width="fit-content" alignSelf="flex-end">
                <Button
                  variant="contained"
                  onClick={saveExecucao}
                  disabled={responseSave.loading}
                >
                  SALVAR
                </Button>
              </Stack>
            </Stack>
          </>
        )}
      </LoaderComponent>
    </>
  );
};

export default RecursoExecucaoPage;

import { Divider, Stack, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TableItens from "../TableItens";

const TabsItens = ({ itens }) => {
  const [execucao, setExecucao] = useState("0");
  const temExecucao = itens.find((item) => item.execucao + "" === "1");
  const listaItens = itens.filter((item) => item.execucao + "" === execucao);

  const handleChange = (event, newValue) => setExecucao(newValue);

  return (
    <Stack width="100%">
      {!!temExecucao && (
        <>
          <Divider />
          <Tabs value={execucao} onChange={handleChange} sx={{mb:'3px'}}>
            <Tab label="Planejamento" value="0" />
            <Tab label="Execução" value="1" />
          </Tabs>
        </>
      )}
      <TableItens itensFiltrados={listaItens} allItens={itens} />
    </Stack>
  );
};

export default TabsItens;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ano: new Date().getFullYear(),
  user: null,
  anosFilter: [],
  filtros: {
    gre: -1,
    escola: -1,
    plano: -1,
    parcela: -1,
    status: -1,
  },
  planoSituacoes: {},
  itemSituacoes: {},
};

export const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    setAnosFilter: (state, action) => {
      state.anosFilter = action.payload;
    },
    setAno: (state, action) => {
      state.ano = action.payload;
    },
    setGre: (state, action) => {
      state.filtros.escola = -1;
      state.filtros.gre = action.payload;
    },
    setEscola: (state, action) => {
      state.filtros.escola = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPlano: (state, action) => {
      state.filtros.parcela = -1;
      state.filtros.plano = action.payload;
    },
    setParcela: (state, action) => {
      state.filtros.parcela = action.payload;
    },
    setStatus: (state, action) => {
      state.filtros.status = action.payload;
    },
    setPlanoSituacoes: (state, action) => {
      state.planoSituacoes = action.payload?{...action.payload}:{};
    },
    setItemSituacoes: (state, action) => {
      state.itemSituacoes = action.payload?{...action.payload}:{};
    },
    clearGlobalState: (state, _action) => {
      state = initialState;
    },
  },
});

export const {
  setAnosFilter,
  setAno,
  setGre,
  setEscola,
  setUser,
  setPlano,
  setParcela,
  setItemSituacoes,
  setPlanoSituacoes,
  setStatus,
} = globalSlice.actions;

export default globalSlice.reducer;

import { Chip, Stack, Typography } from "@mui/material";
import { StyledAccordionSummary } from "app/shared/components";
import React, { useMemo } from "react";
import { FiChevronDown } from "react-icons/fi";

const HeaderAnexo = ({defaultValue, tipo, label}) => {
    const count = useMemo(() => {
        let auxCount = 0;
        defaultValue?.forEach((anexo) => {
            if (anexo.tipo+'' === tipo+'') auxCount++;
        });
        return auxCount
    }, [defaultValue]);

    return (
        <StyledAccordionSummary expandIcon={<FiChevronDown color="#fff" />}>
            <Stack direction='row'>
                <Typography mr="5px">{label}</Typography>
                {!!count && <Chip label={count} color="info" size="small" />}
            </Stack>
        </StyledAccordionSummary>
  );
};

export default HeaderAnexo;

import { Accordion, AccordionDetails, Stack, Typography } from "@mui/material";
import { StyledAccordionSummary } from "app/shared/components";
import { Theme } from "app/shared/utils";
import { MacroAcaoInfo } from "pages/recurso/components";
import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { TableItens } from ".";

const MacroAcaoListForm = ({ macroAcoes, setMacroAcoes }) => {
  
  const onRemove = (index, item_id) => {
    let macroAcoesAux = [...macroAcoes];
    macroAcoesAux[index].itens = macroAcoesAux[index].itens.filter((el) => el.id !== item_id);
    setMacroAcoes([...macroAcoesAux]);
  };

  const onSave = (index, {itemNovo, ...item}, isEdit) => {
    let macroAcoesAux = [...macroAcoes];
    if (isEdit) {
      macroAcoesAux[index].itens = macroAcoesAux[index].itens.map((el) => el.id === item.id ? item : el);
    }else{
      macroAcoesAux[index].itens.push({...item, itemNovo:true})
    }
    setMacroAcoes([...macroAcoesAux]);
  };

  return (
    <>
      <Typography
        variant="body1"
        fontWeight="bold"
        color={Theme.colors.blue.dark}
      >
        MACRO AÇÕES
      </Typography>
      {macroAcoes.length === 0 && (
        <p>Nenhuma ação encontrada para este recurso</p>
      )}
      {macroAcoes.map((acao, index) => (
        <Accordion key={`acc-${index}`} style={{ marginBottom: 8 }}>
          <StyledAccordionSummary expandIcon={<FiChevronDown color="#fff" />}>
            <Stack direction="row">
              <Typography mr="5px">{acao.macro_acao?.nome}</Typography>
            </Stack>
          </StyledAccordionSummary>
          <AccordionDetails>
            <MacroAcaoInfo macroAcao={acao} isEdit={false} />
            <TableItens
              macroAcao={acao?.macro_acao?.nome}
              itens={acao.itens}
              onRemove={(item_id) => onRemove(index, item_id)}
              onSave={(item,isEdit) => onSave(index, item, isEdit)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default MacroAcaoListForm;

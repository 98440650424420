import { Chip, Grid, Typography } from "@mui/material";
import { GenericModal } from "app/shared/components";
import { formatMoney } from "app/shared/helpers";
import { useFormat } from "app/shared/hooks";
import { useSelector } from "react-redux";

const ModalDetalhesItem = ({ item, isOpen, onClose }) => {
  const recursoSelecionado = useSelector((state) => state.recurso.recursoSelecionado);
  const filtros = useSelector((state) => state.global);
  const { formataValorParaReais, aplicarMaskCNPJ } = useFormat();

  const getTotal = (item) => {
    const valorTotal = (Math.round(item.custo_capital) + Math.round(item.custo_custeio)) * item.quantidade;
    return formataValorParaReais(valorTotal);
  }

  const detalhesLista = () => {
    return [
      {
        title: "DESCRIÇÃO",
        description: item.acao,
      },
      {
        title: "CUSTO CAPITAL",
        description: formatMoney(item.custo_capital || 0),
      },
      {
        title: "CUSTO CUSTEIO",
        description: formatMoney(item.custo_custeio || 0),
      },
      {
        title: "QUANTIDADE",
        description: item.quantidade,
      },
      {
        title: "VALOR TOTAL",
        description: getTotal(item),
      },
      {
        title: "CNPJ DO FORNECEDOR",
        description: aplicarMaskCNPJ(item.cnpj_fornecedor) || "Não informado",
      },
    ].map((item) => { 
      return { 
        ...item, 
        description: item.description !== "null" ? item.description : "Não informado"
      } 
    });
  };

  return (
    <GenericModal
      open={isOpen}
      onClose={onClose}
      title={`Detalhes do Item ${item.id}`}
      size="xs"
    >
      <Grid container flexDirection="column" mt="5px">
        <Grid container direction="row" gap={1} mb="20px">
          <Chip size="small" label={`PLANO: ${recursoSelecionado.tipo_plano.descricao}`} />
          <Chip size="small" label={`PARCELA ${recursoSelecionado.parcela.parcela}`} />
          <Chip size="small" label={`${filtros.ano}`} />
        </Grid>
        {detalhesLista().map((info, index) => {
          return (
            <Grid item mb="10px" key={index} flexDirection="column">
              <Typography variant="button" color="#59829d" mb={1}>
                {info.title}
              </Typography>
              <Typography variant="body1">{info.description}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </GenericModal>
  );
};

export default ModalDetalhesItem;

import { Accordion, AccordionDetails, Grid, Stack, Typography } from "@mui/material";
import { StyledAccordionSummary } from "app/shared/components";
import { Theme } from "app/shared/utils";
import React, {  } from "react";
import { FiChevronDown } from "react-icons/fi";
import ItensForm from "../ItensForm";
import { MacroAcaoInfo } from "pages/recurso/components";

const MacroAcaoListForm = ({ macroAcoes, onChange }) => {

  const handleChangeMacroAcao = (macroAcao) => {
      const index = macroAcoes.findIndex(m => m.id === macroAcao.id);
      macroAcoes[index] = macroAcao;
      onChange(macroAcoes);
  } 

  const handleChangeItens = (itens, acaoId) => {
    let index = macroAcoes.findIndex(m => m.id === acaoId);
    macroAcoes[index]['itens'] = [...itens]
    onChange(macroAcoes);
  }

  return (
    <>
      <Grid mt={"20px"}>
        <Typography variant="body1" fontWeight="bold" color={Theme.colors.blue.dark}>
          MACRO AÇÕES
        </Typography>
        {macroAcoes.length === 0 &&
          <p>Nenhuma ação encontrada para este recurso</p>
        }
        {macroAcoes.map((acao, index) => (
          <Accordion key={`acc-${index}`} style={{ marginBottom: 8 }}>
            <StyledAccordionSummary expandIcon={<FiChevronDown color="#fff" />}>
              <Stack direction="row">
                <Typography mr="5px">{acao.macro_acao_descricao}</Typography>
              </Stack>
            </StyledAccordionSummary>
            <AccordionDetails>
              <MacroAcaoInfo macroAcao={acao} onChange={handleChangeMacroAcao} />
              <ItensForm macroAcao={acao?.macro_acao?.nome} valorTotal={acao.valor_total} itens={acao.itens} onChange={(itensAtt) => handleChangeItens(itensAtt, acao.id) } />
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </>
  );
};

export default MacroAcaoListForm;

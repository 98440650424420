import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Theme } from "app/shared/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDate } from "app/shared/hooks";
import { formatMoney } from "app/shared/helpers";
import TabsItens from "../TabsItens";
import { TextGray } from "app/shared/components/Text";

const MacroAcao = () => {
  const [acaoIdSelecionada, setAcaoIdSelecionada] = useState(-1);
  const [acoes, setAcoes] = useState([]);

  const { formatDate } = useDate();

  const recursoSelecionado = useSelector((state) => state.recurso.recursoSelecionado);

  useEffect(() => {
    if (recursoSelecionado?.macro_acao_escolas?.length) {
      setAcaoIdSelecionada(recursoSelecionado.macro_acao_escolas[0].id);
    }
  }, []);

  useEffect(() => {
    if (recursoSelecionado?.macro_acao_escolas) {
      setAcoes(recursoSelecionado.macro_acao_escolas);
    }
  }, [recursoSelecionado]);

  const selecionado = useMemo(() => {
    if (acaoIdSelecionada === -1) {
      return undefined;
    }

    return acoes.find((a) => a.id === acaoIdSelecionada);
  }, [acaoIdSelecionada, acoes]);

  const Info = ({ title, value }) => {
    return (
      <Grid item sm={3} xs={12} mb="10px">
        <Typography fontWeight="bold" variant="body1" fontSize='12px' color={Theme.colors.gray.ligth}>
          {title.toUpperCase()}
        </Typography>
        <Typography variant="body1"  fontSize='12px' >{value}</Typography>
      </Grid>
    );
  };

  const getDate = () => {
    let msg =  "Não informado";
    if (selecionado.inicio || selecionado.termino) {
      if (selecionado.inicio) {
        msg = `${formatDate(new Date(selecionado.inicio).setUTCHours(3))}`;
      }
      if (selecionado.termino) {
        msg += ` - ${formatDate(new Date(selecionado.termino).setUTCHours(3))}`;
      }
      return msg;
    }

    return msg;
  };

  return (
    <>
      <TextGray fontWeight="bold" mb="10px">
        MACRO AÇÕES
      </TextGray>
      <Card variant="outlined" sx={{p:'8px'}}>
        <Grid container>
          <Grid item sm={12} xs={12} md={4}>
            <FormControl fullWidth sx={{marginTop:1}}>
              <InputLabel id="acao-select">Macro ação</InputLabel>
              <Select
                labelId="acao-select"
                id="macro-acao-select"
                value={acaoIdSelecionada}
                size="small"
                label="Macro ação"
                onChange={(e) => setAcaoIdSelecionada(e.target.value)}
              >
                {acoes?.map((a, key) => (
                  <MenuItem key={key} value={a.id}>
                    {a?.macro_acao?.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {selecionado && (
          <>
            <Grid container mt={2} width={"100%"}>
              <Info title={"Período"} value={getDate()} />
              <Info title={"Responsável"} value={selecionado.responsavel || "Não informado"} />
              <Info title={"Financiador"} value={selecionado.financiador || "Não informado"} />
              <Info title={"Valor do crédito"} value={formatMoney(selecionado.valor_total)} />
            </Grid>
            <Grid container width={"100%"}>
              <TabsItens itens={selecionado.itens} />
            </Grid>
          </>
        )}
      </Card>
    </>
  );
};

export default MacroAcao;

import { useDispatch, useSelector } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Chip, Grid, Typography } from "@mui/material";
import { GenericModal } from "app/shared/components";
import {
  areArraysIdenticalById,
  convertToFormData,
  formatMoney,
} from "app/shared/helpers";
import { useRole, useService } from "app/shared/hooks";
import { alteraAnexosItens } from "app/services/recurso";
import { AnexosForm } from "pages/recurso/RecursoCadastro/components";
import AnexosImgPreview from "pages/recurso/RecursoCadastro/components/AnexosForm/AnexosImgPreview";
import AddFilesList from "pages/recurso/RecursoCadastro/components/AnexosForm/AddFilesList";
import { TipoAnexo } from "app/shared/constants";
import toastr from "toastr";
import {
  formataAnexosRequest,
  formatResponseAnexos,
} from "pages/recurso/RecursoCadastro/utils";
import { useEffect } from "react";
import { setAnexosItemRecurso } from "app/redux/reducers/recursoSlice";

const ModalAnexosItem = ({ item, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { isSup } = useRole();
  const { recursoSelecionado } = useSelector((state) => state.recurso);
  const filtros = useSelector((state) => state.global);

  const handleSuccessSave = (anexos) => {
    if(anexos){
      toastr.success("Anexos do item alterados com sucesso!");
      onClose();
      dispatch(setAnexosItemRecurso({anexos,item_id:item.id}));
    }
  };

  const {
    request: alteraAnexosItensReq,
    response: { loading },
  } = useService(alteraAnexosItens, false, handleSuccessSave);

  const formMethods = useForm({
    values: {
      anexos: [],
    },
    mode: "all",
  });

  useEffect(() => {
    formataAnexos();
  }, []);

  const formataAnexos = async () => {
    let anexos = await formatResponseAnexos(item.anexos||[]);
    formMethods.reset({ anexos });
  };

  const { control, getValues } = formMethods;

  const getTotal = () => {
    const valorTotal =
      (Number(item.custo_capital) + Number(item.custo_custeio)) *
      item.quantidade;
    return formatMoney(valorTotal);
  };

  const validacao = (anexos) => {
    if (areArraysIdenticalById(anexos, item.anexos)) {
      toastr.warning("Os anexos devem ser alterados para salvar!");
      return false;
    }
    return true;
  };

  const handleOnSave = () => {
    const { anexos } = getValues();
    if (!validacao(anexos)) {
      return;
    }
    const formatedPayload = {
      item_id: item.id,
      ...formataAnexosRequest(anexos),
    };
    alteraAnexosItensReq(convertToFormData(formatedPayload)).catch((error) => {
      console.log(error); //manter para mostrar erro no console
      toastr.warning("Ocorreu um erro ao salvar os anexos do item!");
    });
  };

  const TiposAnexo = [
    {
      label: "Nota fiscal",
      tipo: TipoAnexo.NOTA_FISCAL,
      CustomBody: AddFilesList,
      defaultExpanded: true,
    },
    { label: "Fotos", tipo: TipoAnexo.FOTOS, CustomBody: AnexosImgPreview },
  ];

  return (
    <GenericModal
      open={isOpen}
      onClose={onClose}
      onSave={!isSup ? handleOnSave : null}
      isLoading={loading}
      title={`Anexos do Item ${item.id}`}
      size="sm"
    >
      <Grid container flexDirection="column">
        <Typography fontSize={"13px"} mb={"4px"}>
          {`AÇÃO: ${item.acao}`}
        </Typography>
        <Typography fontSize={"13px"} mb={"4px"}>
          {`TOTAL: ${getTotal()}`}
        </Typography>
        <Grid container direction="row" gap={1} mb="20px">
          <Chip
            size="small"
            label={`PLANO: ${recursoSelecionado.tipo_plano.descricao}`}
          />
          <Chip
            size="small"
            label={`PARCELA ${recursoSelecionado.parcela.parcela}`}
          />
          <Chip size="small" label={`${filtros.ano}`} />
        </Grid>
        <Grid container overflow={"auto"} maxHeight={"40vh"}>
          <FormProvider {...formMethods}>
            <form style={{ width: "100%" }}>
              <Controller
                control={control}
                name="anexos"
                render={({ field: { onChange, value } }) => (
                  <AnexosForm
                    loading={loading}
                    defaultValue={value}
                    onChange={onChange}
                    tipos={TiposAnexo}
                  />
                )}
              />
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </GenericModal>
  );
};

export default ModalAnexosItem;


export const saveAllMacroAcao = (api, props, controller) => {
  return api.post( "macro_acoes_escola/save_all", props, {
    signal: controller.signal,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};


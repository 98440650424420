export const enviaPlanoAnalise = (api, props, controller) => {
  return api.post("planos/envia_analise", props, {
    signal: controller.signal,
  });
};

export const finalizaPlanoAnalise = (api, props, controller) => {
  return api.post("planos/finaliza_analise", props, {
    signal: controller.signal,
  });
};

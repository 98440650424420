import { Chip } from "@mui/material";

const ChipSituacao = ({
  prefixo = "",
  situacoes,
  status,
  size = "medium",
}) => {
  const situacao = situacoes.find((situacao) => situacao.id === status);

  return (
    situacao && (
      <Chip
        size={size}
        label={prefixo + situacao.descricao}
        color="primary"
        style={{
          backgroundColor: "transparent",
          borderColor: situacao.cor,
          color: situacao.cor,
          fontWeight: "bold",
          fontSize: "11px",
        }}
        variant="outlined"
      />
    )
  );
};

export default ChipSituacao;

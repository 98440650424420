import {
  Divider,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { alteraStatusRecursoItem } from "app/services/recurso/post";
import { GenericModal } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { useState } from "react";
import toastr from "toastr";
import TabelaComparativo from "./TabelaComparativo";
import SelectSituacao from "./SelectSituacao";
import { ChipSituacaoItem } from "app/shared/components/ChipSituacao";
import { useDispatch } from "react-redux";
import { setStatusItemRecurso } from "app/redux/reducers/recursoSlice";

const ModalAnalisarItem = ({ item, allItens, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [situacao, setSituacao] = useState(-1);
  const [motivo, setMotivo] = useState();

  const handleAlteraSituacao = (data) => {
    if (data) {
      
      dispatch(setStatusItemRecurso(data));
      toastr.success("Sucesso ao alterar a situação do item");
      onClose();
    }
  };

  const {
    request: alteraSituacaoReq,
    response: { loading },
  } = useService(alteraStatusRecursoItem, false, handleAlteraSituacao);

  const handleOnSave = () => {
    if (situacao === -1)
      return toastr.warning("Uma nova situação deve ser escolhida!");

    let params = {
      descricao: motivo,
      item_id: item.id,
      status: situacao,
      plano_id:item.plano_id,
    }
    alteraSituacaoReq(params);
  };

  return (
    <GenericModal
      open={isOpen}
      onClose={onClose}
      size="md"
      onSave={handleOnSave}
      isLoading={loading}
      preventCloseClickOutside
    >
      <Grid container>
        <Stack width="100%">
          <Typography mr="10px" variant="h6" color="#59829d">
            ITEM {item.id}
          </Typography>
          <Divider style={{ marginBottom: 10, marginTop: 5 }} />

          <Stack flexDirection="row" width="fit-content" alignItems="baseline" mb={1}>
            <Typography mr="10px" variant="button" color="#59829d">
              SITUAÇÃO ATUAL:
            </Typography>
            <ChipSituacaoItem
              size="small"
              status={item?.situacao?.status}
            />
          </Stack>

          {item.execucao+"" === "1" && <TabelaComparativo {...{allItens, item}}/>}

          <FormControl fullWidth>
            <SelectSituacao {...{situacao, setSituacao, item}}/>
            <Typography variant="button" color="#59829d" marginTop={2} mb={1}>
              MOTIVO
            </Typography>
            <TextField
              multiline
              minRows={4}
              maxRows={10}
              onChange={(e) => setMotivo(e.target.value)}
            />
          </FormControl>
        </Stack>
      </Grid>
    </GenericModal>
  );
};

export default ModalAnalisarItem;

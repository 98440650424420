import { ROLES } from "app/shared/constants";
import useApp from "../useApp";

const useRole = () => {
  const { user } = useApp();
  const isRede = user?.perfil === ROLES.PERFIL_GESTOR;
  const isEscola = user?.perfil === ROLES.PERFIL_ESCOLA;
  const isSup = user?.perfil === ROLES.PERFIL_SUP;
  return {
    isRede,
    isEscola,
    isSup,
  };
};

export default useRole;

import { Grid } from "@mui/material";
import { FileViewer, ListaVazia, UploadComponent } from "app/shared/components";
import { useRole } from "app/shared/hooks";
import React from "react";

const AddFilesList = ({
  defaultValue = [],
  handleUpload = () => {},
  handleRemoveFile = () => {},
  tipo,
  label,
  disabled,
}) => {
  const { isSup } = useRole();
  const listaTipo = defaultValue.filter((file) => file.tipo + "" === tipo + "");
  return (
    <Grid container spacing={1}>
      {!isSup && (
        <Grid item xs={12}>
          <UploadComponent
            message="Escolha arquivos ou arraste aqui (JPEG, JPG, PNG e PDF) até 10MB"
            onUpload={(files) => {
              handleUpload(tipo, files);
            }}
            disabled={disabled}
          />
        </Grid>
      )}
      {listaTipo.map((file) => (
        <Grid item key={file.id}>
          <FileViewer
            file={file}
            onDelete={!isSup ? handleRemoveFile : null}
            disabled={disabled}
          />
        </Grid>
      ))}
      {isSup && (
        <ListaVazia
          mt={1}
          isListaVazia={!listaTipo.length}
          message={`Sem anexos do tipo ${label.toLowerCase()}`}
        />
      )}
    </Grid>
  );
};

export default AddFilesList;

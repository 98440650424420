import { TextBlue, TextGray } from "app/shared/components/Text/styles";
import { useRole } from "app/shared/hooks";
import React from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { ChipSituacaoPlano } from "app/shared/components/ChipSituacao";

const InfoBasePlano = ({ recurso }) => {
  const { isRede, isSup } = useRole();
  const filtros = useSelector((state) => state.global);
  return (
    <>
      <TextBlue fontWeight="bold" variant="h5">
        {recurso?.tipo_plano?.descricao}
      </TextBlue>
      {isRede && (
        <TextGray pr={1}>
          <span style={{ fontWeight: "bold" }}>GRE:</span>{" "}
          {recurso?.escola?.superintendencia?.nome}
        </TextGray>
      )}
      {(isRede || isSup) && (
        <TextGray pr={1}>
          <span style={{ fontWeight: "bold" }}>ESCOLA:</span>{" "}
          {recurso?.escola?.nome}
        </TextGray>
      )}
      <TextGray pr={1}>
        <span style={{ fontWeight: "bold" }}>PARCELA:</span>{" "}
        {recurso?.parcela?.parcela}
      </TextGray>
      <TextGray>
        <span style={{ fontWeight: "bold" }}>ANO:</span> {filtros.ano}
      </TextGray>
      <Stack gap={1} direction='row'>
        <ChipSituacaoPlano status={recurso?.status} prefixo='SITUAÇÃO: '/>
        <ChipSituacaoPlano status={recurso?.status_execucao} prefixo='EXECUÇÃO: '/>
      </Stack>
    </>
  );
};

export default InfoBasePlano;

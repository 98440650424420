import { Stack } from "@mui/material";
import { TextBody, TextGray } from "app/shared/components/Text/styles";
import { useFormat } from "app/shared/hooks";

const InfoDataPlano = ({ recurso }) => {
  const { formataValorParaReais } = useFormat();
  const valor_total =
    recurso?.macro_acao_escolas?.reduce(
      (value, current) => value + Number(current.valor_itens || 0),
      0
    ) || 0;
  return (
    <>
      <Stack mb={1}>
        <TextGray fontWeight="bold">OBJETIVO ESTRATÉGICO</TextGray>
        <TextBody>{recurso?.objetivo_estrategico}</TextBody>
      </Stack>
      <Stack mb={1}>
        <TextGray fontWeight="bold">META</TextGray>
        <TextBody>{recurso?.meta}</TextBody>
      </Stack>
      <Stack mb={1}>
        <TextGray fontWeight="bold">JUSTIFICATIVA</TextGray>
        <TextBody>{recurso?.justificativa}</TextBody>
      </Stack>
      <Stack mb={1}>
        <TextGray fontWeight="bold">VALOR TOTAL DO PLANO</TextGray>
        <TextBody>{formataValorParaReais(valor_total)}</TextBody>
      </Stack>
    </>
  );
};

export default InfoDataPlano;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ListaVazia } from "app/shared/components";
import { formatMoney } from "app/shared/helpers";
import { ChipSituacaoItem } from "app/shared/components/ChipSituacao";
import ActionItem from "../ActionItem";

const TableItens = ({ itensFiltrados, allItens }) => {

  const getTotal = (item) => {
    const valorTotal = (Number(item.custo_capital) + Number(item.custo_custeio)) * item.quantidade;
    return formatMoney(valorTotal);
  };

  const Cell = ({children, rest})=> <TableCell {...rest} sx={{fontSize:'11px'}}>{children}</TableCell>

  return (
    <>
      <TableContainer sx={{border:'1px solid #dad8d8', borderRadius:1 }}>
        <Table size="small" >
          <TableHead>
            <TableRow>
              <Cell>ITEM</Cell>
              <Cell>DESCRIÇÃO</Cell>
              <Cell align="left">CAPITAL (R$)</Cell>
              <Cell align="left">CUSTEIO (R$)</Cell>
              <Cell align="left">QUANTIDADE</Cell>
              <Cell align="left">TOTAL (R$)</Cell>
              <Cell align="left">SITUAÇÃO</Cell>
              <Cell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {itensFiltrados.map((item) => (
              <TableRow key={`row_${item.id}_itens`}>
                <TableCell align="left">{item.id}</TableCell>
                <TableCell component="th" scope="row">
                  {item.acao}
                </TableCell>
                <TableCell align="left">
                  {formatMoney(item.custo_capital)}
                </TableCell>
                <TableCell align="left">
                  {formatMoney(item.custo_custeio)}
                </TableCell>
                <TableCell align="left">{item.quantidade}</TableCell>
                <TableCell align="left">{getTotal(item)}</TableCell>
                {item.situacao && 
                  <TableCell align="left">
                    <ChipSituacaoItem status={item.situacao.status} />
                  </TableCell>
                }
                <TableCell style={{ width: 150 }}>
                  <ActionItem {...{item, allItens}}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {itensFiltrados.length <= 0 && <ListaVazia isListaVazia={itensFiltrados.length <= 0} />}
      
    </>
  );
};

export default TableItens;



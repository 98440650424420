import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatMoney } from "app/shared/helpers";
import { useMemo } from "react";
import { FiAlertCircle } from "react-icons/fi";

const TabelaComparativo = ({ item, allItens }) => {
  const getTotal = (custo_capital, custo_custeio, quantidade) => {
    const valorTotal = (Number(custo_capital) + Number(custo_custeio)) * quantidade;
    return formatMoney(valorTotal);
  };

  const formataDadosTabela = ( { acao, custo_capital, custo_custeio, quantidade }, label) => {
    let total = getTotal(custo_capital, custo_custeio, quantidade);
    custo_capital = formatMoney(custo_capital);
    custo_custeio = formatMoney(custo_custeio);
    return { label, acao, custo_capital, custo_custeio, quantidade, total };
  };

  const [listaItens, objDiferenca] = useMemo(() => {
    let itemExecucao = formataDadosTabela(item, "EXECUÇÃO");
    let lista = [itemExecucao];
    let itemPlanejamento = {};
    if (item.item_planejamento_id) {
      let itemP = allItens.find((it) => it.id === item.item_planejamento_id);
      itemPlanejamento = formataDadosTabela(itemP, "PLANEJAMENTO");
      lista.unshift(itemPlanejamento);
    }
    let diferenca = {};
    for (let key in itemExecucao) {
      if (key !== "label" && itemExecucao[key] !== itemPlanejamento[key]) {
        diferenca[key] = { color: "red" };
      }
    }
    return [lista, diferenca];
  }, [allItens]);

  const getDiferenca = (label, key) => {
    if (label === "EXECUÇÃO") {
      return objDiferenca[key] || {};
    }
    return {};
  };

  const Cell = ({ children, sx, rest }) => (
    <TableCell {...rest} sx={{ fontSize: "11px", ...sx }} align="center">
      {children}
    </TableCell>
  );

  const MsgAlert = () => {
    let msg = "Não há alterações no item de execução!";
    if (Object.keys(objDiferenca).length && listaItens.length > 1) {
      msg = "Há alterações nos valores de execução do item!";
    }
    if (listaItens.length === 1) {
      msg = "Item criado na execução da parcela!";
    }
    return (
      <Stack direction="row" mt={1}>
        <FiAlertCircle color="red" />
        <Typography variant="body2" color="red" ml="5px">
          {msg}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <MsgAlert />
      <TableContainer sx={{ border: "1px solid #dad8d8", borderRadius: 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <Cell />
              <Cell>DESCRIÇÃO</Cell>
              <Cell>CAPITAL (R$)</Cell>
              <Cell>CUSTEIO (R$)</Cell>
              <Cell>QUANTIDADE</Cell>
              <Cell>TOTAL (R$)</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaItens.map((item, key) => (
              <TableRow key={`row_${key}_itens`}>
                <Cell>{item.label}</Cell>
                <Cell sx={getDiferenca(item.label, "acao")}>{item?.acao}</Cell>
                <Cell sx={getDiferenca(item.label, "custo_capital")}>
                  {item.custo_capital}
                </Cell>
                <Cell sx={getDiferenca(item.label, "custo_custeio")}>
                  {item.custo_custeio}
                </Cell>
                <Cell sx={getDiferenca(item.label, "quantidade")}>
                  {item.quantidade}
                </Cell>
                <Cell sx={getDiferenca(item.label, "total")}>{item.total}</Cell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TabelaComparativo;

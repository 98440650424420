import { useEffect, useState } from "react";
import LoaderComponent from "../LoaderComponent";
import { useService } from "app/shared/hooks";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPlano } from "app/redux/reducers/globalSlice";
import { buscaTipoPlanos } from "app/services/plano";

const FiltroPlano = () => {
    const filtros = useSelector((state) => state.global.filtros);
    const dispatch = useDispatch();

    const [planos, setPlanos] = useState([]);

    const handleLoadPlano = (data) => {
        if (data) {
            setPlanos(data);
        }
    }

    const {
        request: getPlanosReq,
        response,
        retry: retryBuscaPlanos
    } = useService(buscaTipoPlanos, false, handleLoadPlano, () => { }, { cache: true, cacheKey: 'getPlanos' });

    useEffect(() => {
        let params = { limit: 0 };
        getPlanosReq(params);
    }, []);

    return (
        <>
            <LoaderComponent
                {...response}
                retry={retryBuscaPlanos}
                message="Buscando planos"
                errorMessage="Falha ao carregar os planos"
            />
            {planos && planos.length > 0 && (
                <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label-plano">Filtrar por plano</InputLabel>
                    <Select
                        labelId="demo-simple-select-label-plano"
                        id="demo-simple-select-plano"
                        value={filtros.plano || -1}
                        size="small"
                        label="Filtrar por plano"
                        notched={true}
                        displayEmpty
                        onChange={(e) => {
                            dispatch(setPlano(e.target.value));
                        }}
                    >
                        <MenuItem value={-1}>
                            Todos
                        </MenuItem>
                        {!!planos && planos.map((plano) => {
                            return (
                                <MenuItem key={plano.descricao} value={plano.id}>
                                    {plano.descricao}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
        </>
    )
}

export default FiltroPlano;
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { setStatus } from "app/redux/reducers/globalSlice";
import { useDispatch, useSelector } from "react-redux";

const FiltroStatus = () => {
  const { filtros, planoSituacoes } = useSelector((state) => state.global);
  const mostraSomenteSemPlano = useSelector((state) => state.recurso.mostraSomenteSemPlano);
  const dispatch = useDispatch();

  let optTodos = {
    descricao: "Todos",
    id: -1,
  };

  const listaStatus = [optTodos].concat(Object.values(planoSituacoes));

  return (
    <FormControl fullWidth pr={1}>
      <InputLabel shrink={true} id="demo-simple-select-label-status">
        Filtrar por status
      </InputLabel>
      <Select
        labelId="demo-simple-select-label-status"
        id="demo-simple-select-status"
        value={filtros.status === "" ? -1 : filtros.status}
        notched={true}
        size="small"
        displayEmpty
        disabled={mostraSomenteSemPlano}
        label="Filtrar por status"
        onChange={(e) => {
          dispatch(setStatus(e.target.value));
        }}
      >
        {listaStatus.map((status) => {
          return (
            <MenuItem key={`status-${status.id}`} value={status.id}>
              {status.descricao}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FiltroStatus;

import { Typography } from "@mui/material";
import { Theme } from "app/shared/utils";

export const TextBody = ({ children, ...rest }) => (
  <Typography variant="body1" fontSize='12px' {...rest}>
    {children}
  </Typography>
);

export const TextGray = ({ children, ...rest }) => (
  <TextBody color={Theme.colors.gray.ligth} {...rest}>
    {children}
  </TextBody>
);
export const TextBlue = ({ children, ...rest }) => (
  <Typography color={Theme.colors.blue.dark} {...rest}>
    {children}
  </Typography>
);

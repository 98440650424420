
export const buscaEntradaSaidaPlanos = (api, props, controller) => {
  return api.get("macro_acoes_escola/valores", {
    signal: controller.signal,
    params: props,
  });
};

export const buscaValoresPorParcelaPlanos = (api, props, controller) => {
  return api.get("macro_acoes_escola/valor_by_parcela", {
    signal: controller.signal,
    params: props,
  });
};

import {
  Box,
  Chip,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { buscaHistoricoItemRecurso } from "app/services/recurso";
import { ListaVazia, LoaderComponent } from "app/shared/components";
import { TimeLine } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { Theme } from "app/shared/utils";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useSelector } from "react-redux";

const style = {
  position: "fixed",
  top: "0",
  bottom: "0",
  right: "0",
  zIndex: 9999,
  width: "100%",
  maxWidth: "400px",
  height: "100vh",
  bgcolor: "background.paper",
  display: "flex",
  flexDirection: "column",
};

const ModalHistorico = ({ open, onClose, paramsId, item }) => {

  const [historicoItem, setHistoricoItem] = useState();
  const { ano, planoSituacoes, itemSituacoes } = useSelector((state) => state.global);
  const { recursoSelecionado } = useSelector((state) => state.recurso);

  useEffect(() => {
    const teste = document.getElementsByClassName("navbar-mobi-nav");
    if (teste.length > 0) {
      teste[0].style["z-index"] = 99;
    }

    return () => {
      if (teste.length > 0) {
        teste[0].style["z-index"] = 9999;
      }
    };
  }, []);

  const formataHistorico = ({item, ...historico}) => {
    const situacao = item?itemSituacoes[historico.status]:planoSituacoes[historico.status];
    let label = item?.execucao==="1"?"Execução do Item":item?"Item":historico.execucao==="1"?"Execução do plano":"Plano";
    let info = {
      label,
      tipoHistoricoid: item?item.id:historico.plano_id,
      labelDescricao: situacao?.descricao || "CRIADO",
      cor: situacao?.cor||'#000',
      item,
    }
    return {...historico, ...info};
  }

  const handleLoadHistoricoItem = (data) => {
    if (data) {
      let items = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      let historico = items.map(item=> formataHistorico(item));
      setHistoricoItem(historico);
    }
  };

  const {
    request: getHistoricoItemReq,
    response,
    retry: retryHistoricoItem,
  } = useService(buscaHistoricoItemRecurso, false, handleLoadHistoricoItem);

  useEffect(() => {
    getHistoricoItemReq({
      ...paramsId,
      with: "item,usuario",
      limit: 0,
    });
  }, []);

  const montaTextoParaHistorico = ({updated_at,label,tipoHistoricoid,labelDescricao,usuario}) => {
    const atualizadoEm = new Date(updated_at);
    const atualizadoEmFormatado = atualizadoEm.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    return `${label} ${tipoHistoricoid} - ${labelDescricao} por ${usuario.nome} (${atualizadoEmFormatado})`;
  };

  const items = (() => {
    return (
      historicoItem?.map((historico) => (
        <Stack
          borderRadius={2}
          p={2}
          bgcolor="#fff"
          borderRight={`5px solid ${historico.cor}`}
        >
          <Typography color={Theme.colors.black} variant="body1">
            {montaTextoParaHistorico(historico)}
          </Typography>
          {historico.itemDescricao && <Typography color={Theme.colors.gray.dark} variant="caption" fontSize={13}>
            Descrição do item: {historico.itemDescricao}
          </Typography>}
          {historico.descricao && <Typography color={Theme.colors.gray.dark} variant="caption">
            Comentário: {historico.descricao}
          </Typography>}
        </Stack>
      )) || <></>
    );
  })();

  const montaDataCriadoEm = () => {
    const criadoEm = new Date(recursoSelecionado.created_at);
    const criadoEmData = criadoEm.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const horas = criadoEm.getHours().toString().padStart(2, "0");
    const minutos = criadoEm.getMinutes().toString().padStart(2, "0");

    return `${criadoEmData} às ${horas}:${minutos}`;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Stack p={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="10px"
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              color={Theme.colors.blue.dark}
            >
              Histórico do plano {recursoSelecionado.tipo_plano.descricao}
            </Typography>
            <IconButton onClick={onClose}>
              <FiX color={Theme.colors.black} size={15} />
            </IconButton>
          </Box>

          <Typography variant="body2" color={Theme.colors.gray.ligth}>
            CRIADO EM: {montaDataCriadoEm()}
          </Typography>
          <Typography variant="body2" color={Theme.colors.gray.ligth}>
            GRE: {recursoSelecionado.escola.superintendencia.nome}
          </Typography>
          <Typography variant="body2" color={Theme.colors.gray.ligth}>
            ESCOLA: {recursoSelecionado.escola.nome}
          </Typography>
          {item && (
            <Stack direction="row" gap={1} my="10px">
              <Chip
                size="small"
                label={`PLANO: ${recursoSelecionado.tipo_plano.descricao}`}
              />
              <Chip
                size="small"
                label={`PARCELA ${recursoSelecionado.parcela.parcela}`}
              />
              <Chip size="small" label={`${ano}`} />
              <Chip size="small" label={`ITEM: ${item.id}`} />
            </Stack>
          )}
        </Stack>
        <Divider sx={{ p: 0 }} />
        <Stack p="10px 10px" width="100%" overflow="auto" height="100%">
          <LoaderComponent
            {...response}
            retry={retryHistoricoItem}
            message="Buscando histórico"
            errorMessage="Falha ao carregar histórico"
          />
          {historicoItem && items?.length > 0 && <TimeLine items={items} />}
          {items?.length === 0 && !response.loading && (
            <ListaVazia isListaVazia message="Nenhum registro encontrado" />
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalHistorico;

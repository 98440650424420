import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { finalizaPlanoAnalise } from "app/services/plano";
import { GenericModal } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import toastr from "toastr";

const FinalizaAnalise = ({ plano_id, redirect, execucao=0 }) => {
  const [open, setOpen] = useState(false);
  const finalizaAnalise = () => {
    let params = {
      plano_id,
      execucao,
    };
    request(params);
  };

  const toggle = () => setOpen(!open);

  const onSuccess = () => {
    let msg = `${execucao?"Execução do plano finalizada":"Plano finalizado"} com sucesso`
    toastr.success(msg);
    toggle();
    redirect();
  };

  const {
    request,
    response: { loading },
  } = useService(finalizaPlanoAnalise,false, onSuccess);

  return (
    <>
      <LoadingButton
        onClick={toggle}
        variant="contained"
        size="small"
        color="primary"
      >
        Finalizar análise {execucao?"da execução":""}
      </LoadingButton>

      {open && (
        <GenericModal
          open={open}
          onClose={toggle}
          size="xs"
          onSave={finalizaAnalise}
          isLoading={loading}
          preventCloseClickOutside
          title={`FINALIZAR ANÁLISE ${execucao?"DA EXECUÇÃO":""}`}
        >
          <Stack m={1}>
            <Typography variant="body1" fontWeight="bold">
              Deseja finalizar a análise do plano?
            </Typography>
            <Stack direction="row" mt={1} gap={1} alignItems="center">
              <FiAlertCircle />
              <Typography variant="body2">
                Ao confirmar, será finalizada a análise dos itens do plano
              </Typography>
            </Stack>
          </Stack>
        </GenericModal>
      )}
    </>
  );
};

export default FinalizaAnalise;

import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { useSelector } from "react-redux";

const { Grid, Typography } = require("@mui/material");
const { buscaPlanosFornecedoresCount } = require("app/services/plano");
const { buscaEntradaSaidaPlanos } = require("app/services/macro-acoes-escola");
const { LoaderComponent, CardInfo } = require("app/shared/components");
const { formatMoney, formatParams } = require("app/shared/helpers");
const { useService, useApp } = require("app/shared/hooks");
const { Theme } = require("app/shared/utils");
const { useEffect } = require("react");

const CardsSection = () => {
  const { ano } = useApp();
  const filtros = useSelector((state) => state.global.filtros);
  const {
    request: getPlanoCounts,
    response: responseCounts,
    retry: retryCounters,
  } = useService(buscaPlanosFornecedoresCount, false, () => { }, () => { }, { cache: true, cacheKey: "getPlanosCount" });

  const {
    request: getEntradaSaida,
    response: responseEntradaSaida,
    retry: retryEntradaSaida,
  } = useService(buscaEntradaSaidaPlanos, false, () => { }, () => { }, { cache: true, cacheKey: "getEntradaSaidaPlanos" });

  const getData = () => {
    const params = {
      "parcela[ano]": ano,
      escola_id: filtros.escola,
      si_id: filtros.gre,
    };
    const formatedParams = formatParams(params);
    getPlanoCounts(formatedParams);
    getEntradaSaida(formatedParams);
  };

  useEffect(() => {
    getData()
  }, [ano, filtros]);

  const counters = responseCounts?.data?.[0];
  const valores = responseEntradaSaida?.data?.[0];

  return (
    <Grid container mb="20px" spacing={1}>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <LoaderComponent
          {...responseCounts}
          retry={retryCounters}
          message="Carregando planos..."
          errorMessage="Falha ao carregar planos"
        >
          <CardInfo label="PLANOS">
            <Typography variant="h5">{counters?.total_planos}</Typography>
          </CardInfo>
        </LoaderComponent>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <LoaderComponent
          {...responseCounts}
          retry={retryCounters}
          message="Carregando fornecedores..."
          errorMessage="Falha ao carregar fornecedores"
        >
          <CardInfo label="FORNECEDORES">
            <Typography variant="h5">{counters?.total_fornecedores}</Typography>
          </CardInfo>
        </LoaderComponent>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <LoaderComponent
          {...responseEntradaSaida}
          retry={retryEntradaSaida}
          message="Carregando valores..."
          errorMessage="Falha ao carregar valores"
        >
          <CardInfo
            label="ENTRADA"
            icon={<FiArrowUp color={Theme.colors.grenn} size={30} />}
          >
            <Typography color={Theme.colors.grenn} variant="h5">
              {formatMoney(valores?.valor_entrada || 0)}
            </Typography>
          </CardInfo>
        </LoaderComponent>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <LoaderComponent
          {...responseEntradaSaida}
          retry={retryEntradaSaida}
          message="Carregando valores..."
          errorMessage="Falha ao carregar valores"
        >
          <CardInfo
            label="SAÍDA"
            icon={<FiArrowDown color={Theme.colors.red} size={30} />}
          >
            <Typography color={Theme.colors.red} variant="h5">
              {formatMoney(valores?.valor_saida || 0)}
            </Typography>
          </CardInfo>
        </LoaderComponent>
      </Grid>
    </Grid>
  );
};

export default CardsSection;

import {
  ListaVazia,
  StyledTableContainer,
} from "app/shared/components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Paginacao } from "app/shared/components";
import ModalEdicaoCredito from "./ModalEdicaoCredito";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useIsFirstRender, useRole, useService } from "app/shared/hooks";
import { LoaderComponent } from "app/shared/components";
import { useFormat } from "app/shared/hooks";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney, getFiltrosSelecionados } from "app/shared/helpers";
import { buscaParcelasSemPlano } from "app/services/parcela";
import { buscaRecursos } from "app/services/recurso";
import { setPaginaAtualListagemRecursos } from "app/redux/reducers/recursoSlice";
import { Theme } from "app/shared/utils";
import { ChipSituacaoPlano } from "app/shared/components/ChipSituacao";

const PAGE_LIMIT = 5;
const TableRecursosAnalise = () => {
  const isFirstRender = useIsFirstRender();
  const dispatch = useDispatch();
  const {isSup} = useRole();
  const {ano,filtros} = useSelector((state) => state.global);
  const recursoSelector = useSelector((state) => state.recurso);
  const mostraSomenteSemPlano = recursoSelector.mostraSomenteSemPlano;
  const pagina = recursoSelector.paginaAtualListagemRecursos;
  const [totalItems, setTotalItem] = useState(0);
  const [isMostrarModalEdicaoCredito, setIsMostrarModalEdicaoCredito] = useState();
  const [recursoSelecionado, setRecursoSelecionado] = useState();
  const [recursosLista, setRecursosLista] = useState([])
  const { formataValorParaReais } = useFormat();
  const navigate = useNavigate();

  const retornaParcela = (parcela) => {
    if (!parcela) return 0;
    return typeof parcela === 'object' ? parcela.parcela : parcela;
  };

  const handleLoadParcelas = (data) => {
    if (data) {
      let list = [];
      if(data.data){
        list = data.data.map(recurso => {
          const { valor_total = 0, credito = 0  } = recurso.macro_acao_escolas?.reduce((value, current ) => {
            value['valor_total'] += current?.valor_itens ?  Number(current.valor_itens) : 0
            value['credito'] += current?.valor_total ? Number(current.valor_total) : 0
            return value 
          },{valor_total: 0, credito: 0}) || {}
          let parcela = retornaParcela(recurso.parcela)
          return {
            ...recurso,
            si_descricao: mostraSomenteSemPlano ? recurso.si_descricao : recurso.superintendencia?.nome,
            escola_nome: mostraSomenteSemPlano ? recurso.nome : recurso?.escola?.nome,
            parcela,
            credito: mostraSomenteSemPlano ? formatMoney(recurso.credito) : formatMoney(credito),
            valor_total: formatMoney(valor_total)
          }
        })
      }
      setRecursosLista(list)
      setTotalItem(data.last_page);
    }
  };

  const { request: getParcelasReq, response: reponseParcelas } = useService(
    mostraSomenteSemPlano ? buscaParcelasSemPlano : buscaRecursos,
    false,
    handleLoadParcelas,
    () => { },
    { 
      cache: true, 
      cacheKey: mostraSomenteSemPlano ? "getParcelasSemPlanoAll" : "getPlanoAll"
    }
  );

  const handleBuscaRecursos = (ignoreCache = false) => {
    const paramsPlano = {
      tipo_plano_id: filtros.plano,
      escola_id: filtros.escola,
      si_id: filtros.gre,
      "parcela[ano]": ano,
      "parcela[parcela]": filtros.parcela,
      status: filtros.status,
      page: pagina,
      limit: PAGE_LIMIT,
      with: "tipo_plano,escola,plano_atual,superintendencia,parcela,anexos,macro_acao_escolas",
      order: ["parcela_id", "tipo_plano_id"],
    };

    const paramsParcelasSemPlano = {
      ano,
      order: ["parcela", "tipo_plano_id"],
      tipo_plano_id: filtros.plano,
      escola_id: filtros.escola,
      si_id: filtros.gre,
      with: "tipo_plano",
      "parcela": filtros.parcela,
      page: pagina,
      limit: PAGE_LIMIT,
    };

    const filteredParams = getFiltrosSelecionados(
      mostraSomenteSemPlano ? paramsParcelasSemPlano : paramsPlano
    );
    setRecursosLista([])
    getParcelasReq(filteredParams, ignoreCache);
  };

  useEffect(() => {
    handleBuscaRecursos();
  }, [pagina, ano, filtros, mostraSomenteSemPlano]);

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(setPaginaAtualListagemRecursos(1));
    }
  }, [filtros, mostraSomenteSemPlano]);

  const handleClickRecurso = (planoId) => {
    navigate(`/recurso/detalhes?id=${planoId}`);
  };

  const isLoading = reponseParcelas.loading;
  const isEmpty = recursosLista?.length === 0 && !isLoading;

  return (
    <Grid container padding={1}>
      <LoaderComponent
        {...reponseParcelas}
        retry={handleBuscaRecursos}
        message="Buscando recursos"
        errorMessage="Falha ao carregar recursos"
      />
      {!isEmpty && !isLoading && (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Typography my="5px" variant="h6" color={Theme.colors.black}>
            Total: {`${reponseParcelas?.data?.total || "..."} recursos`}
          </Typography>
        </Box>
      )}
      {recursosLista?.length > 0 && (
        <>
          <StyledTableContainer>
            <Table sx={{ minWidth: 700 }} size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>PLANO</TableCell>
                  {!isSup && <TableCell align="left">GRE</TableCell>}
                  <TableCell align="left">ESCOLA</TableCell>
                  <TableCell align="left">PARCELA</TableCell>
                  <TableCell align="left" width='130px'>CRÉDITO (R$)</TableCell>
                  {!mostraSomenteSemPlano && <>
                    <TableCell align="left" width='120px'>CUSTO (R$)</TableCell>
                    <TableCell align="left">SITUAÇÃO</TableCell>
                    <TableCell align="left">EXECUÇÃO</TableCell>
                    <TableCell align="left"></TableCell>
                  </>}
                </TableRow>
              </TableHead>
              <TableBody>
                {recursosLista?.map((recurso, key) => 
                  <TableRow key={`row_${key}`}>
                    <TableCell align="left">
                      {recurso.tipo_plano.descricao}
                    </TableCell>
                    {!isSup && <TableCell align="left">
                      {recurso.si_descricao}
                    </TableCell>}
                    <TableCell align="left">
                      {recurso.escola_nome}
                    </TableCell>
                    <TableCell align="left">
                      {recurso.parcela}
                    </TableCell>
                    <TableCell align="left">
                      {formataValorParaReais(recurso?.credito)}
                    </TableCell>
                    {!mostraSomenteSemPlano && <>
                      <TableCell align="left">
                        {formataValorParaReais(recurso?.valor_total)}
                      </TableCell>
                      <TableCell align="left">
                        <ChipSituacaoPlano status={recurso?.status}/>
                      </TableCell>
                      <TableCell align="left">
                        <ChipSituacaoPlano status={recurso?.status_execucao}/>
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          style={{ backgroundColor: "#0288d1", minWidth: 50 }}
                          onClick={() => handleClickRecurso(recurso?.id)}
                        >
                          <BsEyeFill color="white" size={20} />
                        </Button>
                      </TableCell>
                    </>}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <Grid width="100%">
            {totalItems > 0 && (
              <Paginacao
                setNumeroPagina={(pagina) => { dispatch(setPaginaAtualListagemRecursos(pagina)) }}
                pagina={pagina}
                totalItems={totalItems}
                style={{ float: "right", padding: 10 }}
              />
            )}
          </Grid>
        </>
      )}
      <ListaVazia isListaVazia={isEmpty} />
      {recursoSelecionado && (
        <ModalEdicaoCredito
          isMostrarModalEdicaoCredito={isMostrarModalEdicaoCredito}
          recurso={recursoSelecionado}
          currentAnexos={recursoSelecionado.anexos}
          onClose={() => {
            setRecursoSelecionado(null);
            setIsMostrarModalEdicaoCredito(false);
          }}
          onSuccess={() => {
            handleBuscaRecursos(true);
            setIsMostrarModalEdicaoCredito(false);
          }}
          valorCreditoAtual={recursoSelecionado?.credito}
        />
      )}
    </Grid>
  );
};

export default TableRecursosAnalise;

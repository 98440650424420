import { RoutesPath } from "app/shared/constants";
import {
  RecursoPage,
  RecursoDetalhesPage,
  RecursoCadastro,
  RecursoExecucaoPage,
  RecursoBloqueioPage,
} from "pages";

export const RecursoRoutes = [
  {
    index: true,
    element: <RecursoPage />,
  },
  {
    path: RoutesPath.RECURSO_DETAILS.path,
    element: <RecursoDetalhesPage />,
  },
  {
    path: RoutesPath.RECURSO_EXECUCAO.path,
    element: <RecursoExecucaoPage />,
  },
  {
    path: RoutesPath.RECURSO_CADASTRO.path,
    element: <RecursoCadastro />,
  },
  {
    path: RoutesPath.RECURSO_BLOQUEIO.path,
    element: <RecursoBloqueioPage />,
  },
];

import { buscaPlanoTipoSituacoes } from "app/services/plano-tipo-situacoes";
import { useService } from "app/shared/hooks";
import React, { useEffect } from "react";
import LoaderComponent from "../LoaderComponent";
import { setPlanoSituacoes } from "app/redux/reducers/globalSlice";
import { useDispatch } from "react-redux";

const RequestPlanoStatus = () => {
  const dispatch = useDispatch();
  const onSuccessPlanoStatus = (data) => {
    let obj = data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    dispatch(setPlanoSituacoes(obj));
  };
  const { request, response, retry } = useService(
    buscaPlanoTipoSituacoes,
    false,
    onSuccessPlanoStatus,
    () => {},
    { cache: true, cacheKey: "PLANO_STATUS_KEY" }
  );
  useEffect(() => {
    request({ limit: 0 });
  }, []);

  return (
    <>
      <LoaderComponent
        {...response}
        retry={retry}
        message="Carregando situações dos planos"
        errorMessage="Não foi possível carregar situações dos planos"
      />
    </>
  );
};

export default RequestPlanoStatus;

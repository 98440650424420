import {  Grid, Paper } from "@mui/material";
import { HeaderAnoGlobal } from "app/shared/components";
import { FiltrosRecursos, TableRecursosAnalise, TableRecursos } from "./components";
import { useRole } from "app/shared/hooks";

const RecursoPage = () => {
  const { isRede, isSup } = useRole();

  return (
    <>
      <HeaderAnoGlobal
        titulo={"Recursos"}
        subtitulo={""}
        colorTitulo={"#325d87"}
      />
      <Grid
        container
        justifyContent="center"
        component={Paper}
        pr="20px"
        pl="20px"
      >
        <FiltrosRecursos />
      </Grid>
      <Grid  
        component={Paper}     
        my="20px"
      >
        {(isRede || isSup) ? <TableRecursosAnalise />  : <TableRecursos />}
      </Grid>
    </>
  );
};

export default RecursoPage;

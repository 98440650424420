export const convertToFormData = (formObj) => {
  const formData = new FormData();
  const keys = Object.keys(formObj);
  keys.forEach((key) => {
    formData.append(key, formObj[key]);
  });
  return formData;
};

export const getApiUrl = (url_prd, url_hmg) => {
  const url = window.location.href;
  const url_prod = "mobieduca.me";
  const isProd = url.includes(url_prod);
  return isProd ? url_prd : url_hmg;
};

import { useSelector } from "react-redux";
import { ChipSituacao } from ".";

const ChipSituacaoItem = ({ status, size = "small" }) => {
  const { itemSituacoes } = useSelector((state) => state.global);
  return (
    <ChipSituacao
      status={status}
      size={size}
      situacoes={Object.values(itemSituacoes)}
    />
  );
};

export default ChipSituacaoItem;

import { Alert, Box } from "@mui/material";

const ListaVazia = ({ isListaVazia = false, message = "Lista vazia", ...propsBox }) => {
  if (!isListaVazia) return null;

  return (
    <Box width='100%' {...propsBox}>
      <Alert severity="info">{message}</Alert>
    </Box>
  );
};

export default ListaVazia;

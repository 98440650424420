import { Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, GenericModal } from "app/shared/components";
import { Theme } from "app/shared/utils";
import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import toastr from "toastr";

const ModalMacroAcaoForm = ({ open, onClose, onSave, item }) => {
  
  const initialValue = item || {
    inicio: null,
    termino: null,
    descricao: "",
    responsavel: "",
    financiador: "",
  };

  const [newItem, updateNewItem] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    initialValue
  );

  function isDateValid(dateString) {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return false;
    }

    const date = new Date(dateString);
    date.setUTCHours(3);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const normalizedDate = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    return dateString === normalizedDate;
  }

  const isValidForm = () => {
    const errors = [];
    if (!newItem?.responsavel?.trim()) errors.push("Necessário informar responsável");
    if (!newItem?.financiador?.trim()) errors.push("Necessário informar financiador");

    if (newItem.inicio || newItem.termino) {
      if (!isDateValid(newItem.inicio) || !isDateValid(newItem.termino)) errors.push("Selecione uma data válida");

      const inicioDate = new Date(newItem.inicio).setUTCHours(3);
      const terminoDate = new Date(newItem.termino).setUTCHours(3);

      const isValidPeriod = inicioDate <= terminoDate;

      if (!isValidPeriod) errors.push("A Data inicial deve ser menor que a de término");
    }

    errors.forEach((erro) => toastr.warning(erro));

    return errors.length === 0;
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!isValidForm()) return;
    const id = item?.id || `${uuidv4()}-new`;
    onSave?.({ id, ...newItem });
  };

  return (
    <GenericModal title={"EDITAR MACRO AÇÃO"} open={open} onClose={onClose} size="sm" preventCloseClickOutside>
      <Grid container mb="10px" spacing={1}>
        <Grid item xs={12}>
          <p style={{fontSize: 14, color: '#666', fontWeight: 'bold'}} >{item?.macro_acao?.nome}</p>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="body2" fontWeight="bold" color={Theme.colors.blue.dark}>
            INÍCIO
          </Typography>
          <DatePicker
            value={newItem.inicio || ""}
            maxDate={newItem.termino || ""}
            onChangeDate={(newDate) =>  updateNewItem({ inicio: newDate })}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="body2" fontWeight="bold" color={Theme.colors.blue.dark}>
            TÉRMINO
          </Typography>
          <DatePicker
            value={newItem.termino || ""}
            minDate={newItem.inicio || ""}
            onChangeDate={(newDate) => updateNewItem({ termino: newDate })}
          />
        </Grid>
      </Grid>
      <Grid container mb="10px" spacing={1}>
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="body2" fontWeight="bold" color={Theme.colors.blue.dark}>
            RESPONSÁVEL*
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={newItem.responsavel || ""}
            onChange={(e) => updateNewItem({ responsavel: e.target.value })}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography variant="body2" fontWeight="bold" color={Theme.colors.blue.dark}>
            FINANCIADO POR*
          </Typography>
          <TextField
            size="small"
            fullWidth
            value={newItem.financiador || ""}
            onChange={(e) => updateNewItem({ financiador: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" mb="10px">
        <Typography variant="body2" fontWeight="bold" color={Theme.colors.blue.dark}>
          DESCRIÇÃO ADICIONAL
        </Typography>
        <TextField
          fullWidth
          multiline
          minRows={2}
          value={newItem.descricao}
          onChange={(e) => updateNewItem({ descricao: e.target.value })}
        />
      </Grid>
      <Grid container justifyContent="end" mt="10px">
        <Button
          variant="outlined"
          color="secondary"
          style={{
            marginRight: 10,
          }}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          style={{
            justifySelf: "flex-end",
          }}
          variant="contained"
          onClick={handleSave}
        >
          SALVAR
        </Button>
      </Grid>
    </GenericModal>
  );
};

export default ModalMacroAcaoForm;

const useDate = () => {
  const getTextoPeriodo = (inicio, termino) => {
    if (!inicio || !termino) return "-";
    const inicioDate = new Date(inicio).setUTCHours(3);
    const terminoDate = new Date(termino).setUTCHours(3);
    const inicioFormatado = formatDate(inicioDate); 
    const fimFormatado = formatDate(terminoDate);

    return `${inicioFormatado} a ${fimFormatado}`;
  };

  const formatDate = (dt) => {
    const dateFromater = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeZone: "america/sao_paulo",
    });
    return dateFromater.format(dt);
  }

  return {
    getTextoPeriodo,
    formatDate
  };
};

export default useDate;

import { useSelector } from "react-redux";
import { ChipSituacao } from ".";

const ChipSituacaoPlano = ({ prefixo = "", status, size = "small" }) => {
  const { planoSituacoes } = useSelector((state) => state.global);
  return (
    <ChipSituacao
      prefixo={prefixo}
      status={status}
      size={size}
      situacoes={Object.values(planoSituacoes)}
    />
  );
};

export default ChipSituacaoPlano;

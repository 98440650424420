import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recursoSelecionado: null,
  mostraSomenteSemPlano: false,
  paginaAtualListagemRecursos: 1,
  paginaAtualListagemRecursosBoqueio: 1,
  anoFiltro: -1,
};

export const recursoSlice = createSlice({
  name: "recurso",
  initialState: initialState,
  reducers: {
    setRecursoSelecionado: (state, action) => {
      state.recursoSelecionado = action.payload;
    },
    setMostraSomenteSemPlano: (state, action) => {
      state.mostraSomenteSemPlano = action.payload;
    },
    setStatusItemRecurso: (state, action) => {
      let {item,status,plano} = action.payload;
      item.situacao = {status};
      let {macro_acao_escolas} = state.recursoSelecionado;

      let lista = macro_acao_escolas.map(maEscola =>{
        if(maEscola.id === item.macro_acao_escola_id){
          let itens = maEscola.itens.map(maItem => maItem.id === item.id ? item: maItem);
          return {...maEscola,itens}
        }
        return maEscola
      })
      state.recursoSelecionado = { 
        ...state.recursoSelecionado, 
        macro_acao_escolas: lista,
        status: plano.status+'',
        status_execucao: plano.status_execucao+'',
      };
    },
    setAnexosItemRecurso:  (state, action) => {
      let {anexos,item_id} = action.payload;
      let {macro_acao_escolas} = state.recursoSelecionado;
      let lista = macro_acao_escolas.map(maEscola =>{
        let itens = maEscola.itens.map(maItem => maItem.id === item_id ? {...maItem, anexos } : maItem);
        return {...maEscola,itens}
      })
      state.recursoSelecionado = { ...state.recursoSelecionado, macro_acao_escolas: lista};
    },
    setPaginaAtualListagemRecursos: (state, action) => {
      state.paginaAtualListagemRecursos = action.payload;
    },
    setPaginaAtualListagemRecursosBloqueio: (state, action) => {
      state.paginaAtualListagemRecursosBoqueio = action.payload;
    },
    setAnoFiltro: (state, action) => {
      state.anoFiltro = action.payload;
    },
  },
});

export const {
  setStatusItemRecurso,
  setAnexosItemRecurso,
  setRecursoSelecionado,
  setItensRecursoSelecionado,
  setMostraSomenteSemPlano,
  setPaginaAtualListagemRecursos,
  setPaginaAtualListagemRecursosBloqueio,
  setAnoFiltro
} = recursoSlice.actions;

export default recursoSlice.reducer;

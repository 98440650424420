import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalMacroAcaoForm from "./ModalMacroAcaoForm";
import { Theme } from "app/shared/utils";
import { useDate } from "app/shared/hooks";
import { formatMoney } from "app/shared/helpers";

const MacroAcaoInfo = ({ macroAcao, onChange=()=>{}, isEdit=true }) => {
  const [openModal, setOpenModal] = useState(false);
  const { formatDate  } = useDate()

  const Info = ({ title, value }) => {
    return (
      <Grid item sm={4} xs={12} mb="10px">
        <Typography fontWeight="bold" variant="body1" color={Theme.colors.gray.ligth} fontSize='12px'>
          {title.toUpperCase()}
        </Typography>
        <Typography variant="body1" fontSize='11px'>{value || "Não informado"}</Typography>
      </Grid>
    );
  };

  const onSaveMacroAcao = (value) => {
    onChange(value);
    setOpenModal(false);
  }

  const toogle = () => setOpenModal(!openModal)

  return (
    <>
      <Grid container>
        <Info title={"Início"} value={macroAcao.inicio ? formatDate(new Date(macroAcao.inicio).setUTCHours(3)) : undefined } />
        <Info title={"Termino"} value={macroAcao.termino ?formatDate(new Date(macroAcao.termino).setUTCHours(3)) : undefined} />
        <Info title={"Responsável"} value={macroAcao.responsavel} />
        <Info title={"Financiado"} value={macroAcao.financiador} />
        <Info title={"Valor total"} value={formatMoney(macroAcao.valor_total)} />
        <Info title={"Descricao"} value={macroAcao.descricao} />
      </Grid>
      {isEdit &&<Stack >
        <Button onClick={toogle} variant="outlined" size="small" sx={{width:'fit-content', mb:1, p:'1px'}}>
          Editar macro ação
        </Button>
      </Stack>}
      {openModal && (
        <ModalMacroAcaoForm
          item={macroAcao}
          onClose={toogle}
          onSave={onSaveMacroAcao}
          open={openModal}
        />
      )}
    </>
  );
};

export default MacroAcaoInfo;

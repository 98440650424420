import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { setAno, setAnosFilter } from "app/redux/reducers/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { BackButtonContainer } from "./styles";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useApp, useService } from "app/shared/hooks";
import { buscaAnos } from "app/services/aplicacao";
import { useEffect } from "react";
import RequestPlanoStatus from "./RequestPlanoStatus";
import RequestItemStatus from "./RequestItemStatus";

const HeaderAnoGlobal = ({
  titulo = "PAAR",
  subtitulo,
  colorTitulo = "#2a2a2ad1",
  hasBackButton = false,
  children,
  onBack,
  showAnoSelect = true,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anos = useSelector((state) => state.global.anosFilter);
  const { user, ano } = useApp();

  const { request: getAnosReq } = useService(buscaAnos, true, (data) => {
    dispatch(setAnosFilter(data));
  });

  useEffect(() => {
    if (user && ano === null) {
      dispatch(setAno(user.ano_base));
    }
  }, [user]);

  useEffect(() => {
    if ((!anos || anos?.length === 0) && showAnoSelect) {
      getAnosReq();
    }
  }, []);

  const handleGetBack = () => {
    if (onBack) {
      onBack();
      return;
    }
    navigate(-1);
  };

  return (
    <Grid
      my="20px"
      width="100%"
      container
      component={Paper}
      elevation={2}
      p="20px"
      position="relative"
      alignItems="center"
      paddingLeft={hasBackButton ? "70px" : "20px"}
    >
      {hasBackButton && (
        <BackButtonContainer onClick={handleGetBack}>
          <FiArrowLeft size={30} color={colorTitulo} />
        </BackButtonContainer>
      )}
      <RequestPlanoStatus/>
      <RequestItemStatus/>
      <Grid item display="flex" flexDirection="column" md={8} sm={12} xs={12}>
        <Typography
          variant="h5"
          fontWeight="bold"
          alignSelf="left"
          justifySelf="start"
          color={colorTitulo}
        >
          {titulo}
        </Typography>
        {subtitulo && (
          <Typography
            variant="caption"
            alignSelf="left"
            justifySelf="start"
            color={colorTitulo}
          >
            {subtitulo}
          </Typography>
        )}
      </Grid>
      <Grid item md={4} sm={12} xs={12} justifyItems="center">
        {showAnoSelect && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Ano</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ano}
              size="small"
              label="Ano"
              onChange={(e) => dispatch(setAno(e.target.value))}
            >
              {anos?.map((a) => {
                return (
                  <MenuItem key={a.ano} value={a.ano}>
                    {a.ano}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Grid>
      {children}
    </Grid>
  );
};

export default HeaderAnoGlobal;

import { Grid, Typography } from "@mui/material";
import { CardInfo } from "app/shared/components";
import { useSelector } from "react-redux";

const CardsSituacoes = ({ totais = [], selected, click }) => {
  const { planoSituacoes } = useSelector((state) => state.global);
  const counters =
    totais?.reduce((acc, cur) => ({ ...acc, [cur.status]: cur.total }), {}) ||
    {};

  return (
    <Grid container my="10px" spacing={1}>
      {Object.values(planoSituacoes).map((situacao) => (
        <Grid item key={`situacao-${situacao.id}`} xs={12} sm={6} md={4} lg={2}>
          <CardInfo
            label={situacao.descricao}
            isHover={true}
            hasBorder
            isSelected={situacao.id === selected}
            borderColor={situacao.cor}
            hasElevation={false}
            onClick={() => click(situacao.id)}
          >
            <Typography color={situacao.cor} variant="h5">
              {counters[situacao.id] || 0}
            </Typography>
          </CardInfo>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsSituacoes;

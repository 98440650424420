import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { enviaPlanoAnalise } from "app/services/plano";
import { GenericModal } from "app/shared/components";
import { useService } from "app/shared/hooks";
import { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import toastr from "toastr";

const EnviaAnalise = ({ plano_id, redirect, execucao=0 }) => {
  const [open, setOpen] = useState(false);
  const enviaAnalise = () => {
    let params = {
      plano_id,
      execucao,
    };
    request(params);
  };

  const toggle = () => setOpen(!open);

  const onSuccess = () => {
    let msg = `${execucao?"Execução do plano iniciada":"Plano iniciado"} com sucesso`
    toastr.success(msg);
    toggle();
    redirect();
  };

  const {
    request,
    response: { loading },
  } = useService(enviaPlanoAnalise,false, onSuccess);

  return (
    <>
      <LoadingButton
        onClick={toggle}
        variant="contained"
        size="small"
        color="primary"
      >
       {`Enviar ${execucao?"execução ":""}para análise`} 
      </LoadingButton>

      {open && (
        <GenericModal
          open={open}
          onClose={toggle}
          size="xs"
          onSave={enviaAnalise}
          isLoading={loading}
          preventCloseClickOutside
          title={`ENVIAR ${execucao?"EXECUÇÃO ":""}PARA ANÁLISE`}
        >
          <Stack m={1}>
            <Typography variant="body1" fontWeight="bold">
              Deseja enviar o plano para análise?
            </Typography>
            <Stack direction="row" mt={1} gap={1} alignItems="center">
              <FiAlertCircle />
              <Typography variant="body2">
                Ao confirmar, a rede poderá iniciar a análise dos itens do plano
              </Typography>
            </Stack>
          </Stack>
        </GenericModal>
      )}
    </>
  );
};

export default EnviaAnalise;
